import React from "react"

const withLocation = ComponentToWrap => props => {
  let params = new URLSearchParams(props.location.search);
  let category = params.get("category");

  return (
    <>
      <ComponentToWrap {...props} search={category} />
    </>
  )
}

export default withLocation
