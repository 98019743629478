import React, { Component } from "react"
import { Link } from "gatsby"
import Grid from "@material-ui/core/Grid"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import Vspacer from "../Vspacer"
import FilterMenu from "../FilterMenu"
import vSpacing from "../../styles/vSpacing"
import localeData from "../../locale"
import PrimaryBtn from "../Buttons/PrimaryBtn"
import VacancyCard from "../VacancyCard"
import slugify from "../../utils/slugify"
import { currentLocale } from "../../locale/languages"

const FilterContainer = styled.div`
  margin-bottom: ${vSpacing.baseX1};
  ${breakpoints.md`
    margin-bottom: ${vSpacing.baseX7};
  `};
`

const VacancyPostsTitle = styled.h2`
  color: ${props => props.theme.primaryColors.primary};
  margin-bottom: ${vSpacing.baseX3};
  ${breakpoints.md`
    margin-bottom: 0;
  `};
`

const FilterBtnContainer = styled.div`
  width: 100%;
  ${breakpoints.md`
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: auto;
    min-width: 200px;
    max-width: 333px;
  `};
`

const ButtonContainer = styled.div`
  margin: 8px 0;
  width: 100%;
  display: flex;
  align-items: center;

  ${breakpoints.md`
    margin: 0 16px;
  `};
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`

class index extends Component {
  state = {
    limit: 0,
    vacanciesFiltered: [],
    vacancyFilterCountry: "",
    categoryFilter: "",
    allCountries: [],
  }

  constructor(props) {
    super(props)
    this.state = {
      limit: 9,
      vacanciesFiltered: this.props.vacancies,
      vacancyFilterCountry: "",
      categoryFilter: "",
    }
  }

  componentDidMount = () => {
    const { allCountries, selectedCategory } = this.props
    if(selectedCategory) {
      this.handleCategoryilter(selectedCategory);
      setTimeout(function(){
        const elmnt = document.getElementById("vacatures");
        elmnt.scrollIntoView();
        window.scrollBy(0, -200)
       }, 100);
    }
    this.setState({ allCountries })
  }

  handleCountryFilter(filter) {
    const { vacancies } = this.props;
    const { locale } = this.props
    const filterVacancies = vacancies.filter(
      vacancy =>
        vacancy.node.vacancyCountry._rawLocaleTitle[locale].indexOf(filter) >= 0
    )
    this.setState({
      vacanciesFiltered: filterVacancies,
      vacancyFilterCountry: filter,
      categoryFilter: '',
    })
  }

  handleCategoryilter(filter) {
    const { vacancies } = this.props;
    const { locale } = this.props
    const filterVacancies = vacancies.filter(
      vacancy =>
        vacancy.node.vacancyCategory._rawLocaleTitle[locale].indexOf(filter) >= 0
    )
    this.setState({
      vacanciesFiltered: filterVacancies,
      categoryFilter: filter,
      vacancyFilterCountry: '',
    })
  }

  handleResetFilter() {
    const { vacancies } = this.props
    this.setState({
      vacanciesFiltered: vacancies,
      vacancyFilterCountry: '',
      categoryFilter: '',
    })
  }

  handleClick() {
    const { limit } = this.state
    this.setState({
      limit: limit + 9,
    })
  }

  render() {
    const { locale, vacancies, allCategories } = this.props

    const {
      vacanciesFiltered,
      vacancyFilterCountry,
      categoryFilter,
      limit,
      allCountries,
    } = this.state
    const setLocaleInUrl = currentLocale !== locale ? `${locale}/` : ""

    return (
      <div id="vacatures">
        <FilterContainer>
          <Grid container>
            <Grid item xs={12} md={4}>
              <VacancyPostsTitle>
                {localeData._rawAllVacancyTitle[locale]}
              </VacancyPostsTitle>
            </Grid>
            <Grid item md={8} container alignItems="center" justify="flex-end">
              <FilterBtnContainer>
                {allCountries && (
                  <ButtonContainer>
                    <FilterMenu
                      header={localeData._rawAllLocations[locale]}
                      filterName={
                        vacancyFilterCountry || localeData._rawAllLocations[locale]
                      }
                      resetFilter={() => this.handleResetFilter()}
                      filters={allCountries}
                      locale={locale}
                      onFilterClick={filter => this.handleCountryFilter(filter)}
                    />
                  </ButtonContainer>
                )}
              </FilterBtnContainer>
              <FilterBtnContainer>
                {allCategories && (
                  <ButtonContainer>
                    <FilterMenu
                      header={localeData._rawAllCategories[locale]}
                      filterName={
                        categoryFilter || localeData._rawAllCategories[locale]
                      }
                      resetFilter={() => this.handleResetFilter()}
                      filters={allCategories}
                      locale={locale}
                      onFilterClick={filter => this.handleCategoryilter(filter)}
                    />
                  </ButtonContainer>
                )}
              </FilterBtnContainer>
            </Grid>
          </Grid>
        </FilterContainer>
        <Vspacer marginBottom={vSpacing.baseX7}>
          <Grid container justify="flex-start" spacing={16}>
            {vacanciesFiltered && vacanciesFiltered.length >= 1 ? (
              vacanciesFiltered.slice(0, limit).map(vacancy => (
                <Grid item md={4} xs={12} key={vacancy.node.id}>
                  <Link
                    to={`/${setLocaleInUrl}${slugify(
                      vacancy.node._rawLocaleTitle[locale]
                    )}`}
                  >
                    <VacancyCard
                      title={vacancy.node._rawLocaleTitle[locale]}
                      type={vacancy.node.vacancyTypes
                        .map(type => type._rawLocaleTitle[locale])
                        .join(", ")}
                      flag={vacancy.node.vacancyCountry && vacancy.node.vacancyCountry.flag}
                      country={
                        vacancy.node.vacancyCountry && vacancy.node.vacancyCountry._rawLocaleTitle[locale]
                      }
                      category={vacancy.node.vacancyCategory}
                      startingPeriod={vacancy.node._rawStartingPeriod && vacancy.node._rawStartingPeriod[locale]}
                      locale={locale}
                    />
                  </Link>
                </Grid>
              ))
            ) : (
              <Grid item container xs={12} justify="center">
                <p>{localeData._rawNoVacancies[locale]}</p>
              </Grid>
            )}
          </Grid>
        </Vspacer>
        {vacancies && limit < vacancies.length && (
          <Grid container justify="center">
            <PrimaryBtn onClick={() => this.handleClick()}>
              {localeData._rawReadMoreVacancies[locale]}
            </PrimaryBtn>
          </Grid>
        )}
      </div>
    )
  }
}

export default index
