import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import SEO from "../components/seo"
import HeroFullWidth from "../components/HeroFullWidth"
import Section from "../components/Section"
import SvgBorderTop from "../components/SvgBorderTop"
import { Container } from "../components/Container"
import breakpoints from "../styles/breakpoints"
import BlockText from "../components/BlockText"
import UspWithIllustration from "../components/UspWithIllustration"
import AllSanityVacancies from "../components/AllSanityVacancies"
import FaqCategory from "../components/FaqCategory"
import IntroBlockStyle from "../components/IntroBlockStyle"
import LetsConnect from "../components/LetsConnect"
import HeroFullWidthMiddle from "../components/HeroFullWidthMiddle"
import StorySelection from "../components/StorySelection"
import BreadCrumbs from "../components/BreadCrumbs"
import withLocation from "../components/withLocation"

export const query = graphql`
  {
    sanityAllVacanciesPage {
      id
      _rawLocaleTitle
      _rawHeroTitle
      _rawTextBlock1
      image {
        asset {
          gatsbyImageData(width: 1600, fit: FILLMAX, placeholder: BLURRED)
        }
      }
      image2 {
        asset {
          gatsbyImageData(width: 1600, fit: FILLMAX, placeholder: BLURRED)
        }
      }
      faqCategory {
        _rawFaqs
      }
      teamMember {
        name
        degree
        phone
        email
        image {
          asset {
            url
          }
        }
      }
    }
    allSanityVacancy {
      edges {
        node {
          id
          _rawLocaleTitle
          vacancyTypes {
            _rawLocaleTitle
          }
          _rawStartingPeriod
          vacancyCountry {
            flag {
              asset {
                url
              }
            }
            _rawLocaleTitle
          }
          vacancyCategory {
            id
            _rawLocaleTitle
            categoryIcon {
              asset {
                url
              }
            }
          }
        }
      }
    }
    allSanityVacancyCountry {
      edges {
        node {
          id
          _rawLocaleTitle
        }
      }
    }
    allSanityVacancyCategory {
      edges {
        node {
          id
          _rawLocaleTitle
        }
      }
    }
  }
`

const VacancyPageIntro = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
  ${breakpoints.md`
    padding-top: 120px;
    padding-bottom: 120px;
  `};
`

const IndexPage = ({ data, pageContext, search }) => {
  const { locale } = pageContext
  const {
    _rawLocaleTitle,
    _rawHeroTitle,
    _rawTextBlock1,
    faqCategory,
    teamMember,
    image,
    image2,
  } = data.sanityAllVacanciesPage

  const {
    allSanityVacancy,
    allSanityVacancyCountry,
    allSanityVacancyCategory,
  } = data

  return (
    <>
      <SEO title={_rawLocaleTitle[locale]} />
      {_rawLocaleTitle && (
        <BreadCrumbs color="white" pageTitle={_rawLocaleTitle[locale]} locale={locale} />
      )}
      {image && (
        <HeroFullWidth img={image} title={_rawHeroTitle[locale]} />
      )}

      {_rawTextBlock1 && (
        <Section>
          <VacancyPageIntro>
            <Grid container justify="center">
              <Grid item xs={12} md={8}>
                <IntroBlockStyle>
                  <BlockText blocks={_rawTextBlock1[locale]} />
                </IntroBlockStyle>
              </Grid>
            </Grid>
          </VacancyPageIntro>
        </Section>
      )}
      <Section paddingBottom>
        <UspWithIllustration locale={locale} />
      </Section>
      {allSanityVacancy && (
        <SvgBorderTop grey>
          <Container grey paddingTop paddingBottom>
            <Section>
              <AllSanityVacancies
                vacancies={allSanityVacancy.edges}
                allCountries={allSanityVacancyCountry}
                allCategories={allSanityVacancyCategory}
                selectedCategory={search}
                locale={locale}
              />
            </Section>
          </Container>
        </SvgBorderTop>
      )}

      {image2 && (
        <Container grey paddingBottom>
          <HeroFullWidthMiddle img={image2} />
        </Container>
      )}

      <Container grey paddingBottom>
        <StorySelection locale={locale} />
      </Container>

      {faqCategory && (
        <SvgBorderTop>
          <Container paddingTop paddingBottom>
            <Section>
              <FaqCategory category={faqCategory} locale={locale} />
            </Section>
          </Container>
        </SvgBorderTop>
      )}
      {teamMember && (
        <SvgBorderTop grey>
          <Container grey paddingBottom>
            <Section>
              <LetsConnect data={teamMember} locale={locale} />
            </Section>
          </Container>
        </SvgBorderTop>
      )}
    </>
  )
}

export default withLocation(IndexPage)
