import React from "react"
import styled from "styled-components"
import Menu from "@material-ui/core/Menu"
import MenuItem from "../MenuItem"
import { DownChevron } from "../Icons"

const ButtonContainer = styled.div`
  background: transparent;
  border: solid 2px ${props => props.theme.colors.black400};
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 16px;
  width: 100%;
  transition: ${props => props.theme.transitions.hover};

  &:hover {
    cursor: pointer;
    border-color: ${props => props.theme.colors.black300};
  }
  p {
    color: black;
    margin: 0;
    font-weight: 600;
  }
`

const DownChevronContainer = styled.div`
  padding-left: 8px;
  position: absolute;
  right: 16px;
`

class FilterMenu extends React.PureComponent {
  state = {
    anchorEl: null,
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { anchorEl } = this.state
    const { header, filters, filterName, locale, onFilterClick, resetFilter } = this.props
    return (
      <React.Fragment>
        {filters && (
          <React.Fragment>
            <ButtonContainer onClick={this.handleClick}>
              <p>{filterName}</p>
              <DownChevronContainer>
                <DownChevron width="12px" height="12px" />
              </DownChevronContainer>
            </ButtonContainer>
            <Menu
              id="dropdown"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClick={this.handleClose}
            >
              <MenuItem onClick={() => resetFilter()}>
                <p>{header}</p>
              </MenuItem>
              {filters.edges.map(item => (
                <MenuItem
                  onClick={() =>
                    onFilterClick(item.node._rawLocaleTitle[locale])
                  }
                  selected={filterName === item.node._rawLocaleTitle[locale]}
                  key={item.node._rawLocaleTitle[locale] + item.node.id}
                >
                  <p>{item.node._rawLocaleTitle[locale]}</p>
                </MenuItem>
              ))}
            </Menu>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

export default FilterMenu
